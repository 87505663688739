<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ purpose == "edit" ? "Edit" : "Tambah" }}
            </span>
            {{
              roleId == 1
                ? "Dosen"
                : roleId == 2
                ? "Mahasiswa"
                : roleId == 3
                ? "Laboran"
                : roleId == 4
                ? "Admin Korprodi"
                : roleId == 5
                ? "Admin Kepala Lab"
                : roleId == 6
                ? "Admin TU Prodi"
                : "User"
            }}
          </h5>
          <div class="row">
            <div class="col-md-4">
              <b-form-group id="input-group-photo">
                <label class="col-xl-3 col-lg-5 col-form-label pl-0 pt-0"
                  >Gambar</label
                >
                <div class="col-lg-9 col-xl-6 pl-0 pt-0">
                  <div
                    class="image-input image-input-outline"
                    id="kt_profile_avatar"
                  >
                    <div class="image-input-wrapper">
                      <img :src="photo" alt="" />
                    </div>
                    <label
                      class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      "
                      data-action="change"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Change avatar"
                    >
                      <i class="fa fa-pen icon-sm text-muted"></i>
                      <input
                        type="file"
                        name="profile_avatar"
                        accept=".png, .jpg, .jpeg"
                        @change="onFileChange($event)"
                      />
                      <input type="hidden" name="profile_avatar_remove" />
                    </label>
                    <span
                      class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      "
                      data-action="cancel"
                      data-toggle="tooltip"
                      title="Cancel avatar"
                    >
                      <i class="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                    <span
                      class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      "
                      data-action="remove"
                      data-toggle="tooltip"
                      title="Remove avatar"
                      @click="current_photo = null"
                    >
                      <i class="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                  </div>
                </div>
              </b-form-group>
            </div>

            <div class="col-md">
              <b-form-group
                id="input-group-name"
                label="Nama Lengkap:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  placeholder="Nama Lengkap"
                ></b-form-input>
                <small class="text-danger">{{ error.name }}</small>
              </b-form-group>

              <b-form-group
                id="input-group-registration-number"
                :label="roleId == 2 ? 'NIM:' : 'NIP'"
                label-for="input-registration-number"
              >
                <b-form-input
                  id="input-registration-number"
                  v-model="form.registration_number"
                  :placeholder="roleId == 2 ? 'NIM:' : 'NIP'"
                ></b-form-input>
                <small class="text-danger">{{
                  error.registration_number
                }}</small>
              </b-form-group>

              <b-form-group
                id="input-group-birth-date"
                label="Tempat & Tanggal Lahir:"
                label-for="input-sip-birth-date"
              >
                <b-input-group>
                  <b-form-input
                    id="input-birth-place"
                    aria-label="Tempat Lahir"
                    placeholder="Tempat Lahir"
                    v-model="form.birt_place"
                  ></b-form-input>

                  <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    :return-value.sync="form.birt_date"
                    persistent
                    width="350px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <b-form-input
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        id="input-trigger-modal-patient"
                        v-model="form.birt_date"
                        placeholder="Tanggal Lahir"
                      >
                      </b-form-input>
                    </template>

                    <v-date-picker
                      v-if="modal2"
                      v-model="form.birt_date"
                      locale="id"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(form.birt_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </b-input-group>
                <div class="row">
                  <div class="col">
                    <small class="text-danger">{{ error.birt_place }}</small>
                  </div>
                  <div class="col">
                    <small class="text-danger">{{ error.birt_date }}</small>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>

          <b-form-group id="input-group-address">
            <label for="input-address">Alamat Rumah:</label>
            <b-form-textarea
              id="input-address"
              v-model="form.address"
              placeholder="Alamat Rumah"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.address }}</small>
          </b-form-group>

          <!-- <label>Kontak: </label> -->
          <b-form-group
            id="input-group-email2"
            label="Email & No. Wa Aktif"
            label-for="input-email2"
          >
            <b-input-group>
              <b-form-input
                id="input-email2"
                v-model="form.email2"
                placeholder="Alamat Email"
              ></b-form-input>
              <b-form-input
                id="input-phone"
                v-model="form.phone"
                placeholder="Nomor Wa Aktif"
              ></b-form-input>
            </b-input-group>
            <div class="row">
              <div class="col">
                <small class="text-danger">{{ error.email2 }}</small>
              </div>
              <div class="col">
                <small class="text-danger">{{ error.phone }}</small>
              </div>
            </div>
          </b-form-group>

          <template v-if="form.role_id == 2">
            <label>Nama Orang Tua: </label>
            <b-form-group
              id="input-group-father-name"
              label=""
              label-for="input-father-name"
            >
              <b-input-group>
                <b-form-input
                  id="input-father-name"
                  v-model="form.father_name"
                  placeholder="Ayah"
                ></b-form-input>
                <b-form-input
                  id="input-mother-name"
                  v-model="form.mother_name"
                  placeholder="Ibu"
                ></b-form-input>
              </b-input-group>
              <div class="row">
                <div class="col">
                  <small class="text-danger">{{ error.father_name }}</small>
                </div>
                <div class="col">
                  <small class="text-danger">{{ error.mother_name }}</small>
                </div>
              </div>
            </b-form-group>

            <label>Pekerjaan Orang Tua: </label>
            <b-form-group
              id="input-group-father-job"
              label=""
              label-for="input-father-job"
            >
              <b-input-group>
                <b-form-input
                  id="input-father-job"
                  v-model="form.father_job"
                  placeholder="Ayah"
                ></b-form-input>
                <b-form-input
                  id="input-mother-job"
                  v-model="form.mother_job"
                  placeholder="Ibu"
                ></b-form-input>
              </b-input-group>
              <div class="row">
                <div class="col">
                  <small class="text-danger">{{ error.father_job }}</small>
                </div>
                <div class="col">
                  <small class="text-danger">{{ error.mother_job }}</small>
                </div>
              </div>
            </b-form-group>
          </template>

          <!-- Text input -->
          <b-form-group id="input-group-nickname" v-if="form.role_id != 2">
            <label for="input-nickname">Nick Name: </label>
            <b-form-input
              id="input-nickname"
              v-model="form.nickname"
              placeholder="Nick Name"
            ></b-form-input>
            <small class="text-danger">{{ error.nickname }}</small>
          </b-form-group>

          <!-- Text input -->
          <b-form-group
            id="input-group-lecturer-code"
            v-if="form.role_id == 1 || form.role_id == 4 || form.role_id == 5"
          >
            <label for="input-lecturer-code">Kode Dosen: </label>
            <b-form-input
              id="input-lecturer-code"
              v-model="form.lecturer_code"
              placeholder="Kode Dosen"
            ></b-form-input>
            <small class="text-danger">{{ error.lecturer_code }}</small>
          </b-form-group>

          <!-- Text input -->
          <b-form-group
            id="input-group-nidn"
            v-if="form.role_id == 1 || form.role_id == 4 || form.role_id == 5"
          >
            <label for="input-nidn">NIDN: </label>
            <b-form-input
              id="input-nidn"
              v-model="form.nidn"
              placeholder="NIDN"
            ></b-form-input>
            <small class="text-danger">{{ error.nidn }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-educational-stage"
            label="Jenjang Pendidikan:"
            label-for="input-educational-stage"
            v-if="form.role_id == 2"
          >
            <treeselect
              v-model="form.educational_stage_id"
              :multiple="false"
              placeholder="Pilih Jenjang Pendidikan"
              :options="educational_stages"
            />
            <small class="text-danger">{{ error.educational_stage_id }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-major"
            label="Prodi:"
            label-for="input-major"
            v-if="
              form.role_id == 2 ||
              form.role_id == 1 ||
              form.role_id == 4 ||
              form.role_id == 5
            "
          >
            <treeselect
              v-model="form.major_id"
              :multiple="false"
              placeholder="Pilih Prodi"
              :options="majors"
            />
            <small class="text-danger">{{ error.major_id }}</small>
          </b-form-group>

          <!-- <b-form-group
            id="input-group-academic-mentor"
            label="Dosen Pembimbing Akademik:"
            label-for="input-academic-mentor"
            v-if="form.major_id == 1"
          >
            <b-form-input
              id="input-academic-mentor"
              v-model="display.academic_mentor"
              placeholder="Pilih Dosen Pembimbing Akademik"
              readonly
              @click="$bvModal.show('modal-academic-mentor')"
            ></b-form-input>
            <small class="text-danger">{{ error.academic_mentor }}</small>
          </b-form-group> -->
          <template v-if="form.role_id == 2">
            <b-form-group
              id="input-group-academic-mentor"
              label="Dosen Pembimbing Akademik:"
              label-for="input-academic-mentor"
              v-if="
                form.major_id == 1 || form.major_id == 3 || form.major_id == 2
              "
            >
              <treeselect
                v-model="form.academic_mentor"
                :multiple="false"
                placeholder="Pilih Dosen Pembimbing Akademik"
                :options="teachers"
              />
              <small class="text-danger">{{ error.academic_mentor }}</small>
            </b-form-group>
            <b-form-group
              id="input-group-pkl-mentor"
              label="Dosen Pembimbing PKL:"
              label-for="input-pkl-mentor"
              v-if="form.major_id == 1"
            >
              <treeselect
                v-model="form.pkl_mentor"
                :multiple="false"
                placeholder="Pilih Dosen Pembimbing PKL"
                :options="teachers"
              />
              <small class="text-danger">{{ error.pkl_mentor }}</small>
            </b-form-group>
            <b-form-group
              id="input-group-pkl-mentor"
              label="Dosen Pembimbing PKM:"
              label-for="input-pkl-mentor"
              v-if="form.major_id == 3"
            >
              <treeselect
                v-model="form.pkm_mentor"
                :multiple="false"
                placeholder="Pilih Dosen Pembimbing PKM"
                :options="teachers"
              />
              <small class="text-danger">{{ error.pkm_mentor }}</small>
            </b-form-group>
            <b-form-group
              id="input-group-essay-mentor-1"
              label="Dosen Pembimbing Skripsi 1:"
              label-for="input-essay-mentor-1"
              v-if="form.major_id == 1 || form.major_id == 3"
            >
              <treeselect
                v-model="form.essay_mentor_1"
                :multiple="false"
                placeholder="Pilih Dosen Pembimbing Skripsi 1"
                :options="teachers"
              />
              <small class="text-danger">{{ error.essay_mentor_1 }}</small>
            </b-form-group>
            <b-form-group
              id="input-group-essay-mentor-2"
              label="Dosen Pembimbing Skripsi 2:"
              label-for="input-essay-mentor-2"
              v-if="form.major_id == 1 || form.major_id == 3"
            >
              <treeselect
                v-model="form.essay_mentor_2"
                :multiple="false"
                placeholder="Pilih Dosen Pembimbing Skripsi 2"
                :options="teachers"
              />
              <small class="text-danger">{{ error.essay_mentor_2 }}</small>
            </b-form-group>
            <b-form-group
              id="input-group-thesis-mentor-1"
              label="Dosen Pembimbing Tesis 1:"
              label-for="input-thesis-mentor-1"
              v-if="form.major_id == 2"
            >
              <treeselect
                v-model="form.thesis_mentor_1"
                :multiple="false"
                placeholder="Pilih Dosen Pembimbing Tesis 1"
                :options="teachers"
              />
              <small class="text-danger">{{ error.thesis_mentor_1 }}</small>
            </b-form-group>
            <b-form-group
              id="input-group-thesis-mentor-2"
              label="Dosen Pembimbing Tesis 2:"
              label-for="input-thesis-mentor-2"
              v-if="form.major_id == 2"
            >
              <treeselect
                v-model="form.thesis_mentor_2"
                :multiple="false"
                placeholder="Pilih Dosen Pembimbing Tesis 2"
                :options="teachers"
              />
              <small class="text-danger">{{ error.thesis_mentor_2 }}</small>
            </b-form-group>
          </template>

          <!-- Text input -->
          <b-form-group
            id="input-group-password"
            label="Password:"
            label-for="input-password"
            v-if="purpose == 'add'"
          >
            <b-input-group>
              <template #append>
                <b-input-group-text
                  @click="
                    passwordIsVisible == 'password'
                      ? (passwordIsVisible = 'text')
                      : (passwordIsVisible = 'password')
                  "
                >
                  <div v-if="passwordIsVisible == 'password'">
                    <b-icon-eye-slash></b-icon-eye-slash>
                  </div>
                  <div v-if="passwordIsVisible == 'text'">
                    <b-icon-eye></b-icon-eye>
                  </div>
                </b-input-group-text>
              </template>
              <b-form-input
                id="input-password"
                v-model="form.password"
                placeholder="Password"
                :type="passwordIsVisible"
                autocomplet="off"
              ></b-form-input>
            </b-input-group>
            <small class="text-danger">{{ error.password }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-role"
            label="Jabatan:"
            label-for="input-role"
            v-if="roleId == 1"
          >
            <treeselect
              v-model="form.role_id"
              :multiple="false"
              placeholder="Pilih Jabatan"
              :options="roles"
            />
            <small class="text-danger">{{ error.role_id }}</small>
          </b-form-group>

          <template v-if="form.role_id == 2 && purpose == 'edit'">
            <b-form-group label="Status Kelulusan" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.is_graduated"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
              >
                <b-form-radio :value="0">Belum Lulus</b-form-radio>
                <b-form-radio :value="1">Sudah Lulus</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </template>

          <template v-if="form.is_graduated">
            <b-form-group id="input-group-end-date">
              <label for="input-end-date">Tanggal Kelulusan: </label>
              <v-menu
                v-model="modal3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <b-form-input
                    id="input-date"
                    v-model="form.graduate_date"
                    label="Tanggal Kelulusan:"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></b-form-input>
                </template>
                <v-date-picker
                  v-model="form.graduate_date"
                  @input="modal3 = false"
                ></v-date-picker>
              </v-menu>
              <small class="text-danger">{{ error.graduate_date }}</small>
            </b-form-group>
          </template>

          <!-- <b-form-group
          id="input-group-grade"
          label="Tingkat:"
          label-for="input-grade"
          v-if="form.role_id == 2"
        >
          <treeselect
            v-model="form.grade_id"
            :multiple="false"
            placeholder="Pilih Tingkat"
            :options="grades"
          />
          <small class="text-danger">{{ error.grade_id }}</small>
        </b-form-group> -->
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push(routeBack)"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>

    <!-- <ModalAcademicMentor
      id="modal-academic-mentor"
      purpose="modal-academic-mentor"
      secondPurpose
      @chooseData="chooseDataAcademicMentor"
      :filterProps="{ role_id: 1 }"
      title="Pilih Dosen Pembimbing Akademik"
    /> -->
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ModalAcademicMentor from "@/view/components/general/ModalUser2.vue";

export default {
  props: {
    routeBack: String,
    roleId: String,
    form: Object,
    routePost: String,
    currentPhoto: String,
    purpose: String,
    secondPurpose: String,
    messageSuccess: String,
  },
  components: {
    ModalAcademicMentor,
  },
  data() {
    return {
      modal2: false,
      modal3: false,
      error: {
        address: "",
        email: "",
        email2: "",
        grade_id: "",
        name: "",
        nickname: "",
        phone: "",
        photo: "",
        description: "",
        role_id: "",
        school_id: "",
        password: "",
        registration_number: "",
        lecturer_code: "",
        nidn: "",
        school_year_id: "",
        parent_name: "",
        major_id: "",
        birt_date: "",
        birt_place: "",
        father_name: "",
        mother_name: "",
        father_job: "",
        mother_job: "",
        educational_stage_id: "",
        academic_mentor: "",
        essay_mentor_1: "",
        essay_mentor_2: "",
        pkl_mentor: "",
        pkm_mentor: "",
        thesis_mentor_1: "",
        thesis_mentor_2: "",
      },
      display: {
        academic_mentor: "",
      },
      roles: [],
      grades: [],
      schools: [],
      school_years: [],
      current_photo: "/img/default/default-user.svg",
      default_photo: "/img/default/default-user.svg",
      majors: [],
      educational_stages: [],
      teachers: [],
      // other
      passwordIsVisible: "password",
    };
  },
  methods: {
    chooseDataAcademicMentor(value) {
      this.$bvModal.hide("modal-academic-mentor");
      this.form.academic_mentor = value.id;
      this.display.academic_mentor = value.name;
    },
    onFileChange(e) {
      console.log("event", e);
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.photo = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.photo = "";
    },

    async getRoleOption() {
      let response = await module.setTreeSelect(
        "api/roles",
        "?custom_listing=145"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.roles = response.data;
        this.roles.unshift({
          label: "Pilih Jabatan",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getGradeOption() {
      let response = await module.setTreeSelect("api/grades");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.grades = response.data;
        this.grades.unshift({
          label: "Pilih Tingkat",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getSchoolOption() {
      let response = await module.setTreeSelect("api/schools");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.schools = response.data;
        this.schools.unshift({
          label: "Pilih Sekolah",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getSchoolYearOption() {
      let response = await module.setTreeSelect("api/school-years");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.school_years = response.data;
        this.school_years.unshift({
          label: "Pilih Angkatan",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getMajorOption() {
      let response = await module.setTreeSelect("api/majors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.majors = response.data;
        this.majors.unshift({
          label: "Pilih Prodi",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getEducationalStageOption() {
      let response = await module.setTreeSelect("api/educational-stages");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.educational_stages = response.data;
        this.educational_stages.unshift({
          label: "Pilih Jenjang Pendidikan",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getTeacherOption() {
      let response = await module.setTreeSelect(
        "api/users?role_id=1&page_size=100"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.teachers = response.data;
        this.teachers.unshift({
          label: "Pilih Dosen",
          id: "",
          isDisabled: true,
        });
      }
    },

    studentValidation() {
      let status = true;
      if (this.form.father_name == "") {
        status = false;
        this.error.father_name = "Kolom nama ayah wajib diisi";
      } else {
        this.error.father_name = "";
      }
      if (this.form.mother_name == "") {
        status = false;
        this.error.mother_name = "Kolom nama ibu wajib diisi";
      } else {
        this.error.mother_name = "";
      }
      if (this.form.father_job == "") {
        status = false;
        this.error.father_job = "Kolom perkerjaan ayah wajib diisi";
      } else {
        this.error.father_job = "";
      }
      if (this.form.mother_job == "") {
        status = false;
        this.error.mother_job = "Kolom pekerjaan ibu wajib diisi";
      } else {
        this.error.mother_job = "";
      }
      if (this.form.email2 == "") {
        status = false;
        this.error.email2 = "Kolom alamat email wajib diisi";
      } else {
        this.error.email2 = "";
      }
      if (this.form.phone == "") {
        status = false;
        this.error.phone = "Kolom nomor WA aktif wajib diisi";
      } else {
        this.error.phone = "";
      }
      if (this.form.educational_stage_id == "") {
        status = false;
        this.error.educational_stage_id =
          "Kolom jenjang pendidikan wajib diisi";
      } else {
        this.error.educational_stage_id = "";
      }
      if (this.form.major_id == "") {
        status = false;
        this.error.major_id = "Kolom prodi wajib diisi";
      } else {
        this.error.major_id = "";
      }

      if (this.form.major_id == 1) {
        if (this.form.pkl_mentor == "") {
          status = false;
          this.error.pkl_mentor = "Kolom dosen pembimbing pkl wajib diisi";
        } else {
          this.error.pkl_mentor = "";
        }
      }
      if (this.form.major_id == 3) {
        if (this.form.pkm_mentor == "") {
          status = false;
          this.error.pkm_mentor = "Kolom dosen pembimbing pkm wajib diisi";
        } else {
          this.error.pkm_mentor = "";
        }
      }
      if (this.form.major_id == 2) {
        if (this.form.academic_mentor == "") {
          status = false;
          this.error.academic_mentor =
            "Kolom dosen pembimbing akademik wajib diisi";
        } else {
          this.error.academic_mentor = "";
        }
        if (this.form.thesis_mentor_1 == "") {
          status = false;
          this.error.thesis_mentor_1 =
            "Kolom dosen pembimbing tesis 1 wajib diisi";
        } else {
          this.error.thesis_mentor_1 = "";
        }
        if (this.form.thesis_mentor_2 == "") {
          status = false;
          this.error.thesis_mentor_2 =
            "Kolom dosen pembimbing tesis 2 wajib diisi";
        } else {
          this.error.thesis_mentor_2 = "";
        }
      }

      if (this.form.major_id == 1 || this.form.major_id == 3) {
        if (this.form.academic_mentor == "") {
          status = false;
          this.error.academic_mentor =
            "Kolom dosen pembimbing akademik wajib diisi";
        } else {
          this.error.academic_mentor = "";
        }
        if (this.form.essay_mentor_1 == "") {
          status = false;
          this.error.essay_mentor_1 =
            "Kolom dosen pembimbing skripsi 1 wajib diisi";
        } else {
          this.error.essay_mentor_1 = "";
        }
        if (this.form.essay_mentor_2 == "") {
          status = false;
          this.error.essay_mentor_2 =
            "Kolom dosen pembimbing skripsi 2 wajib diisi";
        } else {
          this.error.essay_mentor_2 = "";
        }
      }

      return status;
    },

    async formOnsubmit() {
      if (this.form.role_id == 2) {
        if (this.studentValidation() == false) {
          Swal.fire({
            title: "Gagal",
            text: "Harap periksa kembali form",
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          throw new Error("Validation Error");
        }
      }

      if (this.form.role_id != 2) {
        this.form.school_year_id = 0;
      }
      this.form.school_year_id = 0;
      if (this.form.role_id == 3 || this.form.role_id == 6) {
        this.form.major_id = 0;
      }
      this.form.grade_id = 0;
      this.form.school_id = 0;

      this.form.email = this.form.registration_number;

      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.routePost);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: this.messageSuccess,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        // Swal.fire(response.success.title, response.success.message, "success");
        this.$emit("successPost");
      }
    },
  },
  mounted() {
    this.getRoleOption();
    this.getGradeOption();
    this.getSchoolOption();
    this.getSchoolYearOption();
    this.getMajorOption();
    this.getEducationalStageOption();
    this.getTeacherOption();

    this.form.role_id = this.roleId;
    this.current_photo = this.form.photo;
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      // watch it
      //("Form changed: ", newVal, " | was: ", oldVal);
      this.current_photo = this.form.photo;
      //   this.default_photo = this.form.photo
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>